<template>
  <div>
    <v-text-field
      v-model="displayValue"
      :error-messages="errorMessages"
      :outlined="outlined"
      dense
      :label="label"
      :placeholder="placeholder"
      hide-details="auto"
      :prefix="prefix"
      class="price-input"
      @click="handleClick($event)"
      @input="handleInput"
      @compositionend="handleCompositionend"
      @compositionstart="handleCompositionstart"
      @blur="formatValue($event)"
    ></v-text-field>
  </div>
</template>

<script>
import {
  isNull,
} from '@/core/utils'

export default {
  model: {
    prop: 'price',
    event: 'priceChange',
  },
  props: ['price', 'errorMessages', 'placeholder', 'outlined', 'label', 'prefix', 'fixedNum'],
  data: () => ({
    inputElement: '',
    curVal: '',
    displayValue: '',
  }),
  watch: {
    price(val) {
      this.refreshDisplayValue(val)
    },
  },
  mounted() {
    this.refreshDisplayValue(this.price)
  },
  methods: {
    refreshDisplayValue(val) {
      const regx = new RegExp(`^(-)*(\\d+)\\.(\\d{${this.fixedNum}}).*$`, 'g')
      const initialValue = 0

      let str = isNull(val) ? initialValue.toFixed(this.fixedNum) : `${val}`
      if (str.length > 1 && str.substring(0, 1) === '0' && str.substring(1, 2) !== '.') {
        str = str.substring(1, 2)
      }
      if (str.substring(0, 1) === '.') {
        str = '0.'
      }
      const formatter = str.replace(/[,]/g, '').replace(/[^0-9.]/g, '').replace(regx, '$1$2.$3')

      // console.log('val', formatter, str)
      this.$emit('priceChange', formatter)
      this.$nextTick(() => {
        this.displayValue = formatter.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')

        // console.log('displayValue', this.displayValue)
      })
    },
    formatValue(event) {
      const regx = new RegExp(`^(-)*(\\d+)\\.(\\d{${this.fixedNum}}).*$`, 'g')
      const val = event.target.value
      const str = isNull(val) ? '0' : `${val}`
      const formatter = parseFloat(str.replace(/[,]/g, '').replace(/[^0-9.]/g, '').replace(regx, '$1$2.$3')).toFixed(this.fixedNum)

      this.$emit('priceChange', formatter)
      this.$nextTick(() => {
        this.displayValue = formatter.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        console.log('displayValue', this.displayValue)
      })
    },
    handleClick(event) {
      event.target.select()
      this.lock = false
    },
    handleCompositionstart() {
      this.lock = true
    },
    handleCompositionend() {
      // console.log('handleCompositionend', this.displayValue)
      this.lock = false
      this.refreshDisplayValue(this.displayValue)
    },
    handleInput(val) {
      const clickTime = new Date()

      // const diff = clickTime - this.latestClickTime
      this.latestClickTime = clickTime

      if (this.lock === false) { this.refreshDisplayValue(val) }
    },

  },
}
</script>
<style scoped>
    .price-input>>> input {
      text-align: right
    }
</style>
